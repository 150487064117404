
  @import url('https://fonts.googleapis.com/css2?family=Modak&family=Poppins:wght@100;200;300;400;500;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  html{
    @apply scroll-smooth
  }
}
body{
  font-family: 'Poppins', sans-serif;
}



/* p {
  text-align: justify;
  text-justify: inter-word;
} */


#gallary{
  background-color: #1DB459;
  animation: fader 10s linear infinite;
}

@keyframes fader {
  0% {
    background: url("../public/images/edusampo-logo.jpeg");
    background-repeat: no-repeat;
    width: 100px;
  }
  20% {
    background: url("../public/images/josa.png");
    background-repeat: no-repeat;
  }
  40% {
    background: url("../public/images/finest-future.png");
    background-repeat: no-repeat;
  }
  60% {
    background: url("../public/images/afripro.png");
    background-repeat: no-repeat;
  }
  80% {
    background: url("../public/images/takk.svg");
    background-repeat: no-repeat;
  }
  100% {
    background: url("../public/images/edusampo-logo.jpeg");
    background-repeat: no-repeat;
    width: 100px;
  }
}

.truncate-3-lines-custom {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3; /* Number of lines to show */
}

.carousel-arrow {
  display: block;  /* Ensure the arrows are displayed */
  z-index: 10;     /* Make sure arrows are on top of other content */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background */
  color: white;     /* Arrow color */
  border: none;     /* Remove any border */
  cursor: pointer;
}

.carousel-arrow.left {
  left: 10px;  /* Position arrow on the left */
}

.carousel-arrow.right {
  right: 10px; /* Position arrow on the right */
}

@media (max-width: 768px) {
  .carousel-arrow {
    display: block;  /* Ensure arrows are visible on mobile */
  }
}


.react-grid-carousel-arrow {
  display: block !important;
  opacity: 1 !important;
}